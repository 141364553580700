<template>
  <b-modal
    id="dashboard-modal"
    ref="dashboard"
    title="Dashboard bệnh nhân - Phạm Huỳnh Chí Tâm"
    @show="openStaffPopup"
    @ok="submit"
    size="xl"
    hide-footer
    dialog-class="full-width"
    style="padding-left: 0"
  >
    <b-container fluid>
      <!-- Start statistic -->
      <b-row>
        <b-col>
          <div class="profile">
            <div class="d-flex align-items-center mt-5">
              <avatar
                size="40px"
                :text="item.fullname"
                :src="item.avatart"
                :rounded="true"
              ></avatar>
              <div class="d-flex flex-column ml-5">
                <p
                  class="mb-0"
                  style="font-weight: 600; font-size: 13px; color: #515356"
                >
                  {{ item.name }}
                </p>
                <p class="mt-2 mb-0" style="font-size: 12px; color: #888c9f">
                  Mã số: {{ item.code || 0
                  }}{{
                    item.membershipType
                      ? `, ${item.membershipType}`
                      : ', Thành viên Cơ Bản'
                  }}
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div
            class="d-flex justify-content-end align-items-center"
            style="height: 100%"
          >
            <div>
              <multiselect
                class="mutiselect-rounded"
                v-model="selected"
                @select="handleViewDetail"
                :options="options"
                :searchable="false"
                :show-labels="false"
                label="text"
              ></multiselect>
            </div>
            <div class="ml-2">
              <multiselect
                class="mutiselect-rounded"
                v-model="selectedDay"
                :options="optionsDay"
                :searchable="false"
                :show-labels="false"
                label="text"
              ></multiselect>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between statistic">
            <b-row>
              <b-col
                v-for="(item, index) in statistic"
                :key="index"
                class="statistic__content"
              >
                <p class="m-0 statistic__label">{{ item.label }}</p>
                <p class="m-0 statistic__time">{{ item.time }}</p>
                <p class="value">
                  <span class="value__main"> {{ item.value }}</span>
                  <span class="value__unit">{{ item.unit }}</span>
                </p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <!-- End statistic -->
      <b-row>
        <b-col>
          <b-tabs active-tab-class="pl-0 pr-0" card class="custom">
            <!-- Đường huyết -->
            <b-tab class="tab-custom">
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Đường huyết</span>
                </div>
              </template>
              <BloodSugar />
            </b-tab>
            <!-- Đường huyết -->

            <!-- Huyết áp -->
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Huyết Áp</span>
                </div>
              </template>
              <BloodPressure />
            </b-tab>
            <!-- Huyết áp -->

            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Vận động</span>
                </div>
              </template>
              <Exercise />
            </b-tab>
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Dinh dưỡng</span>
                </div>
              </template>
              <b-container fluid lass="p-0">Dinh dưỡng</b-container>
            </b-tab>
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Cân nặng</span>
                </div>
              </template>
              <Weight />
            </b-tab>
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>Cảm xúc</span>
                </div>
              </template>
              <b-container fluid class="p-0">
                <Feeling />
              </b-container>
            </b-tab>
            <b-tab>
              <template #title>
                <div class="d-flex align-items-end">
                  <span>HbA1c</span>
                </div>
              </template>
              <b-container fluid class="p-0">
                <HBA1C />
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <input-detail :selected.sync="selected" />
    </b-container>
  </b-modal>
</template>

<script>
// import InputDetail from "./modalnputDetail"
import Multiselect from 'vue-multiselect';
import BloodPressure from './tabs/bloodpressure';
import BloodSugar from './tabs/bloodsugar';
import Exercise from './tabs/exercises';
import Feeling from './tabs/feeling';
import Weight from './tabs/weight';
import HBA1C from './tabs/hba1c';
export default {
  name: 'ModalDashboard',
  components: {
    Multiselect,
    BloodPressure,
    BloodSugar,
    Exercise,
    HBA1C,
    Weight,
    Feeling,
    'input-detail': () => import('./modalnputDetail'),
  },
  data() {
    return {
      tabIndex: '',
      bloodPressureSeries: [
        {
          name: 'Tâm thu',
          data: [112, 93, 78, 91, 89, 95, 121, 122, 130],
        },
        {
          name: 'Tâm trương',
          data: [98, 65, 54, 52, 44, 88, 89, 78, 85],
        },
      ],
      singleLineOptions: {
        chart: {
          width: 300,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        colors: ['#01645A', '#B5B5C3'],

        stroke: {
          width: 1,
          curve: 'straight',
        },
        markers: {
          strokeColors: ['#01645A', '#B5B5C3'],
          colors: ['#01645A', '#B5B5C3'],
          shape: 'circle',
          size: 2,
        },
        xaxis: {
          categories: [
            '06/12/20',
            '07/12/20',
            '08/12/20',
            '09/12/20',
            '10/12/20',
            '11/12/20',
            '12/12/20',
            '13/12/20',
            '14/12/20',
          ],
        },
        tooltip: {
          // shared: true,
          y: {
            formatter: function (val) {
              return val + ' lần/phút';
            },
          },
        },
      },

      singleLineSeries: [
        {
          name: 'Series 1',
          data: [90, 65, 45, 77, 68, 55, 81, 72, 59],
        },
      ],
      lineOptions: {
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: 1,
          curve: 'straight',
        },
        title: {
          text: 'Average High & Low Temperature',
          align: 'left',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          shape: 'circle',
          size: 1,
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          title: {
            text: 'Month',
          },
        },
        yaxis: {
          max: 70,
        },
        // legend: {
        //   position: 'top',
        //   horizontalAlign: 'right',
        //   floating: true,
        //   offsetY: -25,
        //   offsetX: -5,
        // },
      },

      columnOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        colors: ['#01645A', '#FDB913'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [
            '04/12/20',
            '04/12/20',
            '04/12/20',
            '05/12/20',
            '05/12/20',
            '05/12/20',
            '12/12/20',
            '13/12/20',
            '14/12/20',
          ],
        },

        fill: {
          opacity: 1,
        },
        tooltip: {
          shared: true,
          y: {
            formatter: function (val) {
              return val + ' mg/DL';
            },
          },
        },
      },
      columnSeries: [
        {
          name: 'Trước ăn',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: 'Sau ăn',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
      ],

      donutOptions: {
        chart: {
          height: 350,
        },
        labels: [
          'Thấp nhất',
          'Rất thấp',
          'Thấp',
          'Trung bình',
          'Tốt',
          'Cao',
          'Rất cao',
          'Cao nhất',
        ],
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              // For offset label
              // offset: 30,
            },
          },
        },
        colors: [
          '#E64A19',
          '#F58220',
          '#FCB276',
          '#8BC34A',
          '#21A567',
          '#FF8E8E',
          '#F44336',
          '#E53935',
        ],
        dataLabels: {
          formatter: function (val) {
            return Math.floor(val) + '%';
          },
        },
      },
      donutSeries: [44, 55, 41, 17, 15, 23, 22, 34],
      statistic: [
        { label: 'Đường huyết', time: 'Hôm qua', value: 260, unit: 'mg/DL' },
        {
          label: 'Huyết áp',
          time: 'Hôm qua',
          value: '125/83',
          unit: 'mm/Hg',
        },
        { label: 'Nhịp tim', time: 'Hôm qua', value: 75, unit: 'lần/phút' },
        { label: 'Vận động', time: 'Hôm qua', value: 366, unit: 'kcal' },
        { label: 'Dinh dưỡng', time: 'Hôm qua', value: 900, unit: 'kcal' },
        { label: 'Cân nặng', time: '13/12/2020', value: 65, unit: 'kg' },
        { label: 'Cảm xúc', time: '01/12/2020', value: '', unit: 'Hạnh phúc' },
        { label: 'HbA1c', time: '15/09/2020', value: 9.0, unit: '%' },
      ],
      trendSelected: { text: 'Sau ăn', value: 'after' },
      trendOptions: [
        { text: 'Trước ăn', value: 'before' },
        { text: 'Sau ăn', value: 'after' },
      ],
      selectedDay: { text: 'Thống kê 7 ngày gần nhất', value: '7day' },
      selected: { text: 'Xem dữ liệu nhập chi tiết', value: null },
      optionsDay: [{ text: 'Thống kê 7 ngày gần nhất', value: '7day' }],
      options: [
        // { text: 'Xem dữ liệu nhập chi tiết', value: null },
        { text: 'Đường huyết', value: 'blood-sugar' },
        { text: 'Huyết Áp', value: 'blood-pressure' },
        { text: 'Vận động', value: 'exercise-table' },
        { text: 'Dinh dưỡng', value: 'food-table' },
        { text: 'Cân nặng', value: 'weight-table' },
        { text: 'Cảm xúc', value: 'feeling-table' },
        { text: 'HbA1c', value: 'hba1c-table' },
      ],
      item: {
        name: 'Phạm Huỳnh Chí Tâm',
        fullname: 'Phạm Huỳnh Chí Tâm',
        avatar:
          'https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light',
      },
    };
  },
  methods: {
    submit() {},
    openStaffPopup() {},
    handleViewDetail() {
      this.$bvModal.show('input-modal');
    },
  },
};
</script>

<style lang="scss">
.mutiselect-rounded {
  width: 210px;
  text-align: center;
  color: #008479;
}
.mutiselect-rounded {
  .multiselect__tags {
    border-radius: 50px !important;
    border-color: #008479;
  }
}
.custom {
  a.nav-link {
    border: 0;
    &.active {
      font-weight: 600;
      color: #01645a !important;
      border-bottom: 2px solid #01645a;
    }
  }
  li.nav-item {
    a.nav-link:hover {
      margin-bottom: 0 !important;
      color: #333;
      border: 0 !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.statistic {
  width: 100%;
  &__content {
    // border-right: 1px solid #ebedf3;
  }
  &__label {
    font-weight: 700;
    color: #b5b5c3;
  }
  &__time {
    color: #b5b5c3;
  }
  .value {
    &__main {
      font-weight: 700;
    }
    &__unit {
      color: #515356;
    }
  }
}
.card {
  background-color: #fcfcfc;
  border-radius: 16px;
}
</style>
