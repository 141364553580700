<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-card class="card">
          <template #header>
            <h6 class="mb-0">Đi bộ & hoạt động khác trong ngày</h6>
          </template>
          <b-overlay :show="activityLoading">
            <b-row>
              <b-col>
                <div style="width: 100%; height: 350px; margin: auto">
                  <v-chart :option="vdonutOptions" autoresize ref="donut" />
                </div>
              </b-col>
              <b-col>
                <div
                  class="d-flex justify-content-center flex-column align-items-center h-100"
                >
                  <div
                    style="display: flex; align-items: baseline"
                    class="w-100"
                  >
                    <img
                      src="/media/users/Clock.png"
                      alt=""
                      class="mr-2 h-100 icon-activity"
                    />
                    <span class="text-xxl text-bold mr-2"
                      ><b>{{ durationRatio || 0 }}</b></span
                    >
                    %
                  </div>
                  <div
                    style="display: flex; align-items: baseline"
                    class="w-100"
                  >
                    <img
                      src="/media/users/Fire.png"
                      alt=""
                      class="mr-2 h-100 icon-activity"
                    />
                    <span class="text-xxl text-bold mr-2"
                      ><b>{{ burnedCalories }} </b></span
                    >kcal
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="exercises exercises__border-left">
                <div class="d-flex align-items-center justify-content-evenly">
                  <img
                    class="mx-2"
                    :src="mainExerciseIconUrl"
                    alt=""
                    style="width: 30px; height: 30px"
                  />
                  <div class="ml-4">
                    <p>{{ mainExerciseName }}</p>
                    <p class="text-xl">
                      <b>{{ mainExerciseDuration || 0 }}</b> phút
                    </p>
                    <p class="text-xl">
                      <b>{{ mainExerciseCalorie }}</b> kcal
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col class="exercises exercises__border-right">
                <div class="d-flex align-items-center">
                  <img
                    class="mx-2"
                    :src="otherExerciseIconUrl"
                    alt=""
                    style="width: 30px; height: 30px"
                  />
                  <div class="ml-4">
                    <p>{{ otherExerciseName }}</p>
                    <p class="text-xl">
                      <b>{{ otherExerciseDuration || 0 }}</b> phút
                    </p>
                    <p class="text-xl">
                      <b>{{ otherExerciseCalorie }}</b> kcal
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="card h-100">
          <template #header>
            <h6 class="mb-0">Mức vận động so với nhóm tuổi</h6>
            <span>{{ description }}</span>
          </template>
          <b-overlay :show="rankLoading" class="h-100">
            <div style="height: 350px" class="mx-auto">
              <v-chart :option="rankOptions" autoresize ref="rank" />
            </div>
            <p class="text-center">
              Bình quân trong nhóm tuổi của bệnh nhân là
              <span class="font-weight-bolder text-success-lighter">
                {{ averagePercent }}</span
              >
              %
            </p>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card class="card">
          <template #header>
            <h6 class="mb-0">Xu hướng thời gian</h6>
          </template>
          <b-overlay :show="durationLoading">
            <div class="w-100 d-flex justify-content-end">
              <b-button class="btn mr-2" style="background-color: #fff"
                >Tổng cộng:
                <span class="font-weight-bolder">{{ trendTotalHours }}</span>
                giờ
                <span class="font-weight-bolder">{{ trendTotalMinutes }}</span>
                phút
              </b-button>
              <b-button class="btn" style="background-color: #fff"
                >{{ targetTitle }}
                <span class="font-weight-bolder text-success">{{
                  trendTargetTime
                }}</span>
                {{ targetUnit }}</b-button
              >
            </div>
            <div style="height: 350px; margin: auto">
              <v-chart :option="timeTrendOptions" autoresize ref="line" />
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="card">
          <template #header>
            <h6 class="mb-0">Xu hướng đốt calo</h6>
          </template>
          <b-overlay :show="caloLoading">
            <div class="w-100 d-flex justify-content-end">
              <b-button class="btn mr-2" style="background-color: #fff"
                >Tổng cộng:
                <span class="font-weight-bolder">{{ trendTotalCalo }}</span>
                kcal</b-button
              >
              <b-button class="btn" style="background-color: #fff"
                >{{ targetTitleCalo }}:
                <span class="font-weight-bolder text-success">{{
                  trendTargetCalo
                }}</span>
                {{ targetUnitCalo }}</b-button
              >
            </div>
            <div style="height: 350px; margin: auto">
              <v-chart :option="caloColumnOptions" autoresize />
            </div>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { use, graphic } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  GridComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  DatasetComponent,
  LegendComponent,
  MarkLineComponent,
} from 'echarts/components';
import { BarChart, PieChart } from 'echarts/charts';
import VChart from 'vue-echarts';

use([
  DatasetComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  MarkLineComponent,
  GridComponent,
  BarChart,
  PieChart,
  CanvasRenderer,
]);
export default {
  name: 'ExerciseTab',
  components: {
    VChart,
  },
  props: {
    selectDay: {
      type: Number,
      default: 1,
    },
    userId: {
      type: String,
    },

    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeTrendOptions: {
        barMaxWidth: 40,
        color: [],
        legend: {
          show: true,
          bottom: 0,
        },

        dataZoom: {
          type: 'inside',
          start: 0,
        },
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',

            // scale: true,
            // Convert minutes to hours
            // axisLabel: {
            //   formatter: function (val) {
            //     console.log('value :>> ', val);
            //     return `${(val / 60).toFixed(1)} h`;
            //   },
            // },
          },
        ],
        series: [
          {
            name: 'Đạt mục tiêu',
            type: 'bar',
            color: '#21A567',
            data: [],
            label: {
              show: true,
              position: 'top',
            },
            markLine: {
              silent: true,
              symbol: 'none',
              lineStyle: {
                type: 'solid',
                color: '#21A567',
              },
              data: [],
            },
          },
          {
            name: 'Chưa đạt mục tiêu',
            type: 'bar',
            data: [],
            color: '#F58220',

            barGap: '-100%',
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      },
      caloColumnOptions: {
        barMaxWidth: 40,
        color: [],
        legend: {
          show: true,
          bottom: 0,
        },

        dataZoom: {
          type: 'inside',
          start: 0,
        },
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            // scale: true,
          },
        ],
        series: [
          {
            name: 'Đạt mục tiêu',
            type: 'bar',
            color: '#21A567',
            data: [],
            label: {
              show: true,
              position: 'top',
            },
            markLine: {
              silent: true,
              symbol: 'none',
              lineStyle: {
                type: 'solid',
                color: '#21A567',
              },
              data: [],
            },
          },
          {
            name: 'Chưa đạt mục tiêu',
            type: 'bar',
            data: [],
            color: '#F58220',

            barGap: '-100%',
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      },

      vdonutOptions: {
        title: {
          text: '',
        },
        series: [
          {
            type: 'gauge',
            // min: 0,
            // max: 70,
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },

            progress: {
              show: true,
              overlap: true,
              roundCap: true,
            },
            axisLine: {
              lineStyle: {
                // width: 30
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 0,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                name: '',
                value: '',
                label: '',
                itemStyle: {
                  color: '#72CB9C',
                },
                detail: {
                  offsetCenter: ['0%', '40%'],
                },
              },
            ],
            title: {
              fontSize: 18,
              offsetCenter: ['0%', '0%'],
              // fontWeight: 'bold',
            },
            detail: {
              width: 30,
              height: 14,
              fontSize: 12,
              fontWeight: 400,
              color: '#000',
              borderColor: 'auto',
              borderRadius: 0,
              formatter: function () {
                return ``;
              },
            },
          },
        ],
      },
      rankOptions: {
        tooltip: {
          show: false,
        },

        xAxis: {
          show: false,
          type: 'category',
          axisLine: {
            show: false,
            onZero: false,
            onZeroAxisIndex: 1,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          show: false,
        },
        series: [
          {
            markPoint: {
              data: [],
              symbolOffset: [0, -25],
            },
            data: [
              {
                value: [5, 95],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [10, 90],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [15, 85],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [20, 80],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [25, 75],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [30, 70],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [35, 65],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [40, 60],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [45, 55],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [50, 50],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(134, 208, 135, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [55, 45],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [60, 40],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [65, 35],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [70, 30],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba(255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba(134, 208, 135, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [75, 25],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba( 255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba( 255, 185, 19, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [80, 20],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba( 255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba( 255, 185, 19, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [85, 15],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba( 255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba( 255, 185, 19, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [90, 10],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba( 255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba( 255, 185, 19, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [95, 5],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba( 255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba( 255, 185, 19, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
              {
                value: [100, 0],
                itemStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: 'rgba( 255, 185, 19, 1)' },
                    { offset: 1, color: 'rgba( 255, 185, 19, 1)' },
                  ]),
                  borderRadius: [21, 22, 22, 22],
                },
              },
            ],
            type: 'bar',
          },
        ],
        // series: {
        //   realtimeSort: true,
        //   type: 'bar',
        //   itemStyle: {
        //     borderRadius: [21, 22, 22, 22],
        //   },
        //   encode: { x: 'name', y: 'score' },
        // },
      },
      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        periodFilterType: this.selectDay,
        takeAll: true,
      },

      trendTotalMinutes: 0,
      trendTotalHours: 0,
      trendTargetTime: 0,
      targetUnit: null,
      targetTitle: null,
      trendTotalCalo: 0,
      trendTargetCalo: 0,
      targetUnitCalo: null,
      targetTitleCalo: null,
      burnedCalories: 0,

      durationRatio: null,
      otherExerciseCalorie: 0,
      otherExerciseDuration: null,
      otherExerciseName: null,
      otherExerciseIconUrl: null,
      mainExerciseCalorie: 0,
      mainExerciseDuration: null,
      mainExerciseName: null,
      mainExerciseIconUrl: null,
      description: null,
      averagePercent: 0,

      rankLoading: false,
      activityLoading: false,
      caloLoading: false,
      durationLoading: false,
    };
  },
  computed: {
    patientId() {
      return this.userId;
    },
    optionsChart() {
      return this.options;
    },
  },
  watch: {
    selectDay: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal;
        this.loadData();
      },
    },
  },
  methods: {
    async getRank() {
      this.rankLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Exercise/Rank', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        this.description = data.description;
        this.averagePercent = data.averagePercent;
        this.rankOptions.series[0].data.map((e, index) => {
          if (
            e.value[0] === data.averagePercent &&
            data.partientPercent != data.averagePercent
          ) {
            e.itemStyle.color = '#72CB9C';
          }

          if (e.value[0] === data.partientPercent) {
            e.itemStyle.color = '#01645A';
            this.rankOptions.series[0].markPoint.data = [
              {
                value: data.averagePercent,
                xAxis: index === 0 ? index : index - 1,
                yAxis: e.value[1],
              },
            ];
          }
        });
        this.rankLoading = false;
        if (this.$refs.rank) {
          this.$refs.rank?.setOption(this.rankOptions);
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
        this.rankLoading = false;
      }
    },
    async loadTrendTime() {
      this.timeTrendOptions.series[0].data = [];
      this.timeTrendOptions.series[1].data = [];
      this.timeTrendOptions.xAxis[0].data = [];
      this.durationLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Exercise/Trend/Duration', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        this.trendTotalMinutes = data.total % 60;
        this.trendTotalHours = Math.floor(data.total / 60);
        this.targetUnit = data.targetUnit;
        this.targetTitle = data.targetTitle;
        this.trendTargetTime = data.target;
        this.timeTrendOptions.series[0].markLine.data = [
          {
            name: 'Đạt mục tiêu',
            yAxis: data.target,
          },
        ];
        const items = data.trendItems.items;
        if (items && items.length) {
          for (const el of items) {
            if (data.target <= el.duration) {
              this.timeTrendOptions.series[0].data.push(el.duration);
              this.timeTrendOptions.series[1].data.push('-');
            } else {
              this.timeTrendOptions.series[1].data.push(el.duration);
              this.timeTrendOptions.series[0].data.push('-');
            }

            this.timeTrendOptions.xAxis[0].data.push(
              el.date
                ? this.$moment(el.date * 1000).format('DD/MM/YYYY')
                : `${this.$moment(el.firstDateOfWeek * 1000).format(
                    'DD',
                  )}-${this.$moment(el.lastDateOfWeek * 1000).format(
                    'DD',
                  )}/${this.$moment(el.lastDateOfWeek * 1000).format('MM')}`,
            );
          }
        }
        this.durationLoading = false;
      } catch (error) {
        this.durationLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    async loadTrendCalo() {
      this.caloColumnOptions.series[0].data = [];
      this.caloColumnOptions.series[1].data = [];
      this.caloColumnOptions.xAxis[0].data = [];
      this.caloLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Exercise/Trend/Calorie', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        this.trendTotalCalo = Math.round(data.total || 0);
        this.targetUnitCalo = data.targetUnit;
        this.targetTitleCalo = data.targetTitle;
        this.trendTargetCalo = data.target;
        this.caloColumnOptions.series[0].markLine.data = [
          {
            name: 'Đạt mục tiêu',
            yAxis: data.target,
          },
        ];
        const items = data.trendItems.items;
        if (items && items.length) {
          for (const el of items) {
            if (data.target <= el.burnedCalories) {
              this.caloColumnOptions.series[0].data.push(
                Math.round(el.burnedCalories),
              );
              this.caloColumnOptions.series[1].data.push('-');
            } else {
              this.caloColumnOptions.series[1].data.push(
                Math.round(el.burnedCalories),
              );
              this.caloColumnOptions.series[0].data.push('-');
            }

            this.caloColumnOptions.xAxis[0].data.push(
              el.date
                ? this.$moment(el.date * 1000).format('DD/MM/YYYY')
                : `${this.$moment(el.firstDateOfWeek * 1000).format(
                    'DD',
                  )}-${this.$moment(el.lastDateOfWeek * 1000).format(
                    'DD',
                  )}/${this.$moment(el.lastDateOfWeek * 1000).format('MM')}`,
            );
          }
        }
        this.caloLoading = false;
      } catch (error) {
        this.caloLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },

    async getActivitySummary() {
      this.activityLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Exercise/Summary', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        this.burnedCalories = Math.round(data.burnedCalories || 0);
        this.durationRatio = data.durationRatio;
        this.otherExerciseCalorie = Math.round(data.otherExerciseCalorie || 0);
        this.otherExerciseDuration = data.otherExerciseDuration;
        this.otherExerciseName = data.otherExerciseName;
        this.otherExerciseIconUrl = data.otherExerciseIconUrl.url;
        this.mainExerciseCalorie = Math.round(data.mainExerciseCalorie || 0);
        this.mainExerciseDuration = data.mainExerciseDuration;
        this.mainExerciseName = data.mainExerciseName;
        this.mainExerciseIconUrl = data.mainExerciseIconUrl.url;
        this.targetDuration = data.targetDuration;
        // TODO: undo
        this.factDuration = data.factDuration;

        this.vdonutOptions.series[0].data[0].name = `${data.factDuration} / ${data.targetDuration} phút`;

        this.vdonutOptions.series[0].data[0].value = data.targetDuration;

        this.vdonutOptions.series[0].data[0].value =
          (data.factDuration / data.targetDuration) * 100;
        if (this.$refs.donut) {
          this.$refs.donut?.setOption(this.vdonutOptions);
        }
        this.activityLoading = false;
      } catch (error) {
        this.$toastr.e(error, 'ERROR');
        this.activityLoading = false;
      }
    },

    loadData() {
      this.loadTrendTime();
      this.loadTrendCalo();
      this.getRank();
      this.getActivitySummary();
    },
  },

  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.text-success-lighter {
  color: #21a567;
}
.exercises {
  border-radius: 6px;
  border: 1px solid #ebedf3;
  &__border {
    &-left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &-right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.donut {
  max-width: 170px;
  min-width: 170px;
}
.text-xl {
  font-size: 1.3rem;
}
.text-xxl {
  font-size: 24px;
}
.icon-activity {
  max-width: 20px;
  max-height: 20px;
}
</style>
